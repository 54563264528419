import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from 'app/reducers/rootReducer';
import reduxCookiesMiddleware, {getStateFromCookies} from 'redux-cookies-middleware';
import {createInitialUserData} from 'app/reducers/currentUserReducer';

const paths = {
  'currentUser.districtSlug': {name: 'selectedDistrictSlug'},
  'currentUser.authRedirect': {name: 'auth-redirect'},
};

let composeEnhancers = compose;

if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25});
}

const customSetCookie = (res, name, value, expiry) => {
  const defaultExpiry = 365;
  const expires = expiry ? expiry : defaultExpiry;
  const DAY_IN_MS = 86400000;
  const expiryDate = new Date(Date.now() + expires * DAY_IN_MS);
  if (res) {
    return res.cookie(name, value, {expires: expiryDate, sameSite: 'lax'});
  } else {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; path=/; expires=${expiryDate.toUTCString()}; samesite=lax;`;
  }
};

const makeStore = (history, url, req, res) => {
  // reads a cookie from the express request object / writes a cookie from the express request object
  let getCookie;
  const options = {
    setCookie: (name, value, expiry) => customSetCookie(res, name, value, expiry),
  };
  if (req && res) {
    getCookie = (name) => {
      return name in req.cookies ? req.cookies[name] : null;
    };
  }

  let preloadedState = {
    currentUser: createInitialUserData(),
  };
  if (typeof window !== 'undefined' && window.REDUX_DATA) {
    preloadedState = window.REDUX_DATA;
  }
  preloadedState = getStateFromCookies(preloadedState, paths, getCookie);
  return createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, reduxCookiesMiddleware(paths, options)))
  );
};

export default makeStore;
