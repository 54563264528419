import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // Log errors occuring with csr to sentry (necessary for production mode otherwise errors are not reported)
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // Needs to return null on hasError to capture exceptions with sentry, instead of 'null' we can render any custom fallback UI
      return null;
    }
    return this.props.children;
  }
}

export {ErrorBoundary};
