import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';

const Info = styled(Typography)`
  &&& {
    color: ${COLORS.darkestGrey};
  }
  margin: ${SIZES['16']} 0;
  text-align: center;
`;

const LeftAlignedInfo = styled(Info)`
  &&& {
    margin-top: 0;
    text-align: left;
  }
`;

// Needed for Links which open a new tab and LinkOnComponent can not be used
const ExternalLink = styled.a`
  text-decoration: ${(props) => (props.decoration ? props.decoration : 'none')};
  color: ${(props) => (props.color ? props.color : 'inherit')};
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: ${(props) => (props.decoration ? props.decoration : 'none')};
  }
`;

const Note = styled(Typography)`
  margin: ${SIZES['16']} 0;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export {Info, LeftAlignedInfo, Note, ExternalLink};
