const initialData = false;

const DialogModeReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'UPDATE_DIALOG_MODE':
      return action.data;
    default:
      return state;
  }
};

export {DialogModeReducer};
