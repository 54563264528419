const initialData = null;

const AuthPageErrorReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'AUTH_PAGE_ERROR':
      return action.data;
    default:
      return state;
  }
};

export {AuthPageErrorReducer};
