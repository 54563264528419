import React from 'react';
import {ExternalLink} from 'app/styles/InfoText';

const AccountWithoutEmailError = () => {
  return (
    <>
      Aus technischen Gründen kann ein Konto nicht ohne E-Mail-Adresse erstellt werden. Nähere Information findest du{' '}
      <ExternalLink
        decoration='underline'
        target='_blank'
        href='https://support.fupa.net/support/solutions/articles/75000030738-registrierung-mit-facebook-zugriff-auf-e-mail-adresse-wichtig'
      >
        hier
      </ExternalLink>
      .
    </>
  );
};

export {AccountWithoutEmailError};
