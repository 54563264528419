import styled from 'styled-components';
import {Link} from 'react-router-dom';

const LinkOnComponent = styled(Link)`
  text-decoration: ${(props) => (props.decoration ? props.decoration : 'none')};
  color: ${(props) => (props.color ? props.color : 'inherit')};
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: ${(props) => (props.decoration ? props.decoration : 'none')};
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

export {LinkOnComponent, LinkContainer};
