const initialData = '';

const JwtCookieNameReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'JWT_COOKIE_NAME_SET':
      return action.data;
    default:
      return state;
  }
};

export {JwtCookieNameReducer};
