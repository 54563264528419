const splitDisplayName = displayName => {
  const hasIdentifier = displayName.includes('+');
  const names = displayName.split(' ');
  let lastName, firstName;
  if (names.length > 2) {
    lastName = names[names.length - 1];
    firstName = displayName.split(lastName)[0].trim();
  } else {
    lastName = names[1];
    firstName = names[0].trim();
  }

  if (hasIdentifier) {
    lastName = lastName.replace(/[+]/g, ' ');
    firstName = firstName.replace(/[+]/g, ' ');
  }

  if (!lastName) {
    return {
      firstName: '',
      lastName: firstName,
    };
  }

  return {
    lastName,
    firstName,
  };
};

const windowIsDefined = typeof window !== 'undefined';

const isNativeFlutterAndroidApp = () => {
  // JS channel is only supported in new native Flutter app
  return windowIsDefined && window.native_android;
};

const isNativeFlutterIOSApp = () => {
  // JS channel is only supported in new native Flutter app
  return typeof windowIsDefined && window.native_ios;
};

const isNativeFlutterApp = () => {
  return isNativeFlutterAndroidApp() || isNativeFlutterIOSApp();
};

export {splitDisplayName, isNativeFlutterApp};
