import React from 'react';
import ReactDOM from 'react-dom';
import {Dialog} from 'app/components/dialog/Dialog';
import {Button} from '@fupa/fupa-uikit';

export const openDialog = ({rejectAction, resolveAction, title, text}) => {
  const target = document.getElementById('modal-root');

  return new Promise((resolve, reject) => {
    const handleReject = () => {
      reject();
      ReactDOM.unmountComponentAtNode(target);
    };
    const handleResolve = () => {
      resolve();
      ReactDOM.unmountComponentAtNode(target);
    };
    const actions = [];
    let actionsContent = null;
    if (rejectAction) {
      actions.push(<Button justify='center' onClick={handleReject} label={rejectAction} />);
    }
    if (resolveAction) {
      actions.push(<Button justify='center' onClick={handleResolve} label={resolveAction} />);
    }
    if (actions.length) {
      actionsContent = <>{actions.map((action) => action)}</>;
    }

    ReactDOM.render(
      <Dialog
        open={true}
        actionsContent={actionsContent}
        actionsAlign={actions.length === 1 ? 'flex-end' : 'space-between'}
        title={title}
        text={text}
      />,
      target
    );
  });
};
