import React from 'react';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Dialog as MuiDialog, DialogActions, DialogContent} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles({
  container: {
    // Disable *:focus from template.html for this component
    backgroundColor: 'transparent',
  },
  paper: {
    maxWidth: '30rem',
    width: '100%',
  },
  title: {
    backgroundColor: COLORS.fupaSecondary,
    height: SIZES[48],
    padding: SIZES[12],
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: COLORS.white,
  },
  actions: props => ({
    padding: SIZES[16],
    justifyContent: props.actionsAlign,
  }),
});

export const Dialog = ({actionsAlign = 'space-between', actionsContent, open, text, title}) => {
  const classes = useStyles({actionsAlign});

  const showActions = actionsContent ? (
    <DialogActions className={classes.actions}>{actionsContent}</DialogActions>
  ) : null;

  return (
    <MuiDialog classes={{paperWidthXs: classes.paper, container: classes.container}} open={open} maxWidth={'xs'}>
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant='title1'>{title}</Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant='body1'>{text}</Typography>
      </DialogContent>
      {showActions}
    </MuiDialog>
  );
};
