const sendEventToGA4 = (eventName, eventParams) => {
  if (ENVIRONMENT !== 'local') {
    if (eventName === 'page_view') {
      gtag('get', process.env.GTAG_ID, 'user_properties', (up) => {
        const params = {fupa_region_property: up?.fupa_region, ...eventParams};
        gtag('event', eventName, params);
      });
    } else {
      gtag('event', eventName, eventParams);
    }
  }
};

const setDimensionToGA4 = (paramValuePair) => {
  if (ENVIRONMENT !== 'local') {
    gtag('set', paramValuePair);
  }
};

export {sendEventToGA4, setDimensionToGA4};
