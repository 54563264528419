const initialData = {
  // Set bool to detect when observer is called for the first time so firebase initializing completed
  // and access to user state can be verified
  initializing: true,
  user: {},
  claims: {},
  // Value of cookie set by caller (fupa-app)
  districtSlug: '',
  // State to check for email verification
  verificationPending: true,
  // Value of cookie to detect if init request is caused by redirect after signup/login with federated provider to show skeleton correctly
  authRedirect: false,
};

const CurrentUserReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'CURRENT_USER_UPDATE':
      return {
        ...state,
        user: action.data.user,
        claims: action.data.claims,
        initializing: false,
        verificationPending: !action.data.claims.email_verified,
      };
    case 'CURRENT_USER_CLEAR':
      return {
        ...initialData,
        districtSlug: state.districtSlug,
        initializing: false,
      };
    case 'VERIFICATION_PENDING_UPDATE':
      return {...state, verificationPending: action.data};
    case 'AUTH_REDIRECT_UPDATE':
      return {...state, authRedirect: action.data};
    default:
      return state;
  }
};

// will be used to pre-populate preloadedState in store.js
// We need to create a copy, otherwise initialData would act as global variable and would share data across different users
// Reason: redux-cookies-middleware.getStateFromCookies() does not copy data in preloadedState, it just extends it (which creates global variable/state)
const createInitialUserData = () => {
  // Need to do deep copy of initial state to avoid sharing nested district to different requests
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  return {...copyInitialState};
};

export {CurrentUserReducer, createInitialUserData};
