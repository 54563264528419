function updateCurrentUser(data) {
  return {
    type: 'CURRENT_USER_UPDATE',
    data,
  };
}

function clearCurrentUser() {
  return {
    type: 'CURRENT_USER_CLEAR',
  };
}

function updateVerificationPending(data) {
  return {
    type: 'VERIFICATION_PENDING_UPDATE',
    data,
  };
}

function updateAuthRedirect(data) {
  return {
    type: 'AUTH_REDIRECT_UPDATE',
    data,
  };
}

export {updateCurrentUser, clearCurrentUser, updateVerificationPending, updateAuthRedirect};
