import React, {useEffect, useState} from 'react';
import {LinkOnComponent} from 'app/styles/link';
import {fetchEmailSignInMethods} from 'app/services/firebase';
import {useLocation} from 'react-router';

const EmailInUseError = ({email}) => {
  const {search} = useLocation();
  const [signInMethod, setSignInMethod] = useState({text: '', link: '/auth/login'});

  useEffect(() => {
    fetchEmailSignInMethods(email)
      .then((methods) => {
        if (methods.length > 0) {
          const recommendedMethod = methods[0];
          setSignInMethod(provider[recommendedMethod]);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const provider = {
    password: {text: 'mit E-Mail und Passwort', link: '/auth/login/email'},
    'google.com': {text: 'mit deinem Google-Account', link: '/auth/login'},
    'facebook.com': {text: 'mit deinem Facebook-Account', link: '/auth/login'},
    'apple.com': {text: 'mit deinem Apple-Account', link: '/auth/login'},
  };

  return (
    <>
      Du kannst dich{' '}
      <LinkOnComponent to={{pathname: signInMethod.link, search}} decoration='underline'>
        {signInMethod.text} hier einloggen
      </LinkOnComponent>
      .
    </>
  );
};

export {EmailInUseError};
