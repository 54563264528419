/**
 * Stores the JWT as a cookie to submit it to the redirect server.
 */
function setJwtCookieName(data) {
  return {
    type: 'JWT_COOKIE_NAME_SET',
    data,
  };
}

export {setJwtCookieName};
