// Initial value set to root of caller if no redirect query present in request
const initialData = '/select';

const RedirectUrlReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'REDIRECT_URL_SET':
      return action.data;
    default:
      return state;
  }
};

export {RedirectUrlReducer};
