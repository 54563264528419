import React, {createContext, useContext} from 'react';

const windowIsDefined = typeof window !== 'undefined';

const isNativeApp = () => {
  if (windowIsDefined) {
    // Legacy Native: iOS (Deprecated)
    if (window.webkit?.messageHandlers.native) {
      return window.webkit.messageHandlers.native;
    }
    if (window.native) {
      // Legacy Native: Android postMessage channel (Deprecated)
      // Flutter Core WebView: android & ios postMessage channel available on window.native
      return window.native;
    }
  }

  return null;
};

const generateHandler = key => {
  if (isNativeApp()) {
    return value => {
      let message = value ? `${key}:${value}` : key;

      if (key === 'triggerSignIn') {
        message = value;
      }
      isNativeApp().postMessage(message);
    };
  }
  return () => {};
};

const availableHandlers = {
  login: {
    name: 'login',
    handler: generateHandler('login'),
  },
  logout: {
    name: 'logout',
    handler: generateHandler('logout'),
  },
  triggerSignIn: {
    name: 'triggerSignIn',
    handler: generateHandler('triggerSignIn'),
  },
};

const NativeContext = createContext();

const isNativeFlutterAndroidApp = () => {
  // JS channel is only supported in new native Flutter app
  return typeof window !== 'undefined' && window.native_android;
};

const isNativeFlutterIOSApp = () => {
  // JS channel is only supported in new native Flutter app
  return typeof window !== 'undefined' && window.native_ios;
};

const isNativeFlutterApp = () => {
  return !!(isNativeFlutterAndroidApp() || isNativeFlutterIOSApp());
};

const isLegacyAndroidApp = () => {
  return windowIsDefined && window.native && !isNativeFlutterApp();
};

const isLegacyIOSApp = () => {
  return windowIsDefined && window.webkit?.messageHandlers?.native;
};

const isAndroid = isLegacyAndroidApp() || isNativeFlutterAndroidApp();
const isIos = isLegacyIOSApp() || isNativeFlutterIOSApp();

const getPlatform = () => {
  if (isAndroid) {
    return 'android';
  }
  if (isIos) {
    return 'ios';
  }
  return 'web';
};

export const NativeProvider = ({children}) => {
  const nativeInfo = {
    isNativeApp: !!isNativeApp(), // false for web, true for legacy native apps ios and android, true for flutter apps
    isFlutter: isNativeFlutterApp(), // false for legacy native apps ios and android, true for flutter apps
    platform: getPlatform(),
    ...availableHandlers,
  };

  return <NativeContext.Provider value={nativeInfo}>{children}</NativeContext.Provider>;
};

export const useNative = () => {
  return useContext(NativeContext);
};
