import {getCurrentHub, Replay} from '@sentry/browser';
import * as Sentry from '@sentry/browser';

const sessionReplayInstance = new Replay({
  sessionSampleRate: 0.03, // 3% of sessions will be recorded
  errorSampleRate: 0,
  maskAllText: false,
  blockAllMedia: false,
});

const initSessionReplayIntegration = () => {
  const hasReplayIntegration = getCurrentHub()
    .getClient()
    ?.getIntegration(Replay);
  if (!hasReplayIntegration) {
    getCurrentHub()
      .getClient()
      ?.addIntegration(sessionReplayInstance);
  }
};

const setSentryTags = tag => {
  const {name, value} = tag;
  Sentry.configureScope(scope => {
    scope.setTag(name, value);
  });
};

export {initSessionReplayIntegration, setSentryTags};
