import {useEffect} from 'react';
import {setDimensionToGA4} from 'app/components/helpers/gtagHelpers';

const sendConsentStatusToAnalytics = (tcData) => {
  const countPurposeConsents = Object.keys(tcData.purpose.consents).length;
  // We have ten tcf purposes where the user has the choice to give its opt-in
  // Note: if the __cmp api is used there are twelve purposes listed because of two custom pruposes which are not included in the tcf standard
  const consent_status = countPurposeConsents === 10 ? 'approved' : countPurposeConsents > 0 ? 'custom' : 'rejected';
  // Send to GA4
  setDimensionToGA4({user_properties: {fupa_consent_status: consent_status}});
};

const useConsent = () => {
  useEffect(() => {
    const checkForConsent = setInterval(function () {
      if (typeof __tcfapi === 'function') {
        window.clearInterval(checkForConsent);
        __tcfapi('addEventListener', 2, function (tcData) {
          if (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') {
            sendConsentStatusToAnalytics(tcData);
          }
        });
      }
    }, 50);
  }, []);
};

export {useConsent};
