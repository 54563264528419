import loadable from '@loadable/component';

const SignUpPage = loadable(() => import('../routes/signUp/SignUpPage'), {
  fallback: (() => null)(),
});

const SignUpTeaser = loadable(() => import('../routes/signUp/SignUpTeaser'), {
  fallback: (() => null)(),
});

const SignUpWithEmailPage = loadable(() => import('../routes/signUp/SignUpWithEmailPage'), {
  fallback: (() => null)(),
});

const LogInPage = loadable(() => import('../routes/logIn/LogInPage'), {
  fallback: (() => null)(),
});

const LogInWithEmailPage = loadable(() => import('../routes/logIn/LogInWithEmailPage'), {
  fallback: (() => null)(),
});

const FinishSignUpPage = loadable(() => import('../routes/signUp/FinishSignUpWrapperPage'), {
  fallback: (() => null)(),
})

const ResetPasswordPage = loadable(() => import('../routes/ResetPasswordPage'), {
  fallback: (() => null)(),
});

const ChangeEmailPage = loadable(() => import('../routes/changeEmail/ChangeEmailWrapperPage'), {
  fallback: (() => null)(),
});

const ChangePasswordPage = loadable(() => import('../routes/changePassword/ChangePasswordWrapperPage'), {
  fallback: (() => null)(),
});

const CompleteProfilePage = loadable(() => import('../routes/completeProfile/CompleteProfileWrapperPage'), {
  fallback: (() => null)(),
});

const FavoriteTeamTeaserPage = loadable(() => import('../routes/favoriteTeam/FavoriteTeamTeaserPage'), {
  fallback: (() => null)(),
});

const FavoriteTeamSuggestPage = loadable(() => import('../routes/favoriteTeam/FavoriteTeamSuggestPage'), {
  fallback: (() => null)(),
});

const FavoriteTeamConfirmPage = loadable(() => import('../routes/favoriteTeam/FavoriteTeamConfirmPage'), {
  fallback: (() => null)(),
});

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ '../routes/notFound/NotFoundPage'),
  {
    fallback: (() => null)(),
  },
);

const routes = [
  {
    path: '/auth/signup/teaser',
    strict: true,
    exact: true,
    component: SignUpTeaser,
  },
  {
    path: '/auth/signup',
    strict: true,
    exact: true,
    component: SignUpPage,
  },
  {
    path: '/auth/signup/email',
    strict: true,
    exact: true,
    component: SignUpWithEmailPage,
  },
  {
    path: '/auth/login',
    strict: true,
    exact: true,
    component: LogInPage,
  },
  {
    path: '/auth/login/email',
    strict: true,
    exact: true,
    component: LogInWithEmailPage,
  },
  {
    path: '/auth/finish-signup',
    component: FinishSignUpPage,
  },
  {
    path: '/auth/reset-password',
    component: ResetPasswordPage,
  },
  {
    path: '/auth/change-email',
    component: ChangeEmailPage,
  },
  {
    path: '/auth/change-password',
    component: ChangePasswordPage,
  },
  {
    path: '/auth/complete-profile',
    component: CompleteProfilePage,
  },
  {
    path: '/auth/favorite-team',
    strict: true,
    exact: true,
    component: FavoriteTeamTeaserPage,
  },
  {
    path: '/auth/favorite-team/select',
    strict: true,
    exact: true,
    component: FavoriteTeamSuggestPage,
  },
  {
    path: '/auth/favorite-team/confirm',
    strict: true,
    exact: true,
    component: FavoriteTeamConfirmPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export default routes;
