import axios from 'axios';

const authApi = axios.create({baseURL: process.env.AUTH_API_URL});

const setAuthorizationHeader = (jwt) => {
  authApi.defaults.headers.common['Authorization'] = jwt ? `Bearer ${jwt}` : '';
};

const postEmailVerified = (jwt) => {
  setAuthorizationHeader(jwt);
  return authApi.post('/auto-verify');
};

const getRemoteAuth = (hostUrl, jwt) => {
  setAuthorizationHeader(jwt);
  return authApi.get('/remote-authentication', {params: {hostUrl}});
};

export {postEmailVerified, getRemoteAuth};
