import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {RedirectUrlReducer} from 'app/reducers/RedirectUrlReducer';
import {JwtCookieNameReducer} from 'app/reducers/JwtCookieNameReducer';
import {ReAuthenticationReducer} from 'app/reducers/ReAuthenticationReducer';
import {CurrentUserReducer} from 'app/reducers/currentUserReducer';
import {AuthPageErrorReducer} from 'app/reducers/AuthPageErrorReducer';
import {ButtonTypeReducer} from 'app/reducers/ButtonTypeReducer';
import {DialogModeReducer} from 'app/reducers/DialogModeReducer';

const rootReducer = history =>
  combineReducers({
    currentUser: CurrentUserReducer,
    authPageError: AuthPageErrorReducer,
    redirectUrl: RedirectUrlReducer,
    jwtCookieName: JwtCookieNameReducer,
    reAuthenticationNeed: ReAuthenticationReducer,
    buttonType: ButtonTypeReducer,
    dialogMode: DialogModeReducer,
    router: connectRouter(history),
  });

export default rootReducer;
