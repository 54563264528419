import React from 'react';
import {LinkOnComponent} from 'app/styles/link';
import {useLocation} from 'react-router';

const UnknownEmailError = () => {
  const {search} = useLocation();
  return (
    <>
      Es existiert kein Konto mit der angegebenen E-Mail Adresse.{' '}
      <LinkOnComponent to={{pathname: '/auth/signup', search}} decoration='underline'>
        Jetzt Konto erstellen
      </LinkOnComponent>
      .
    </>
  );
};

export {UnknownEmailError};
