import React from 'react';
import {EmailInUseError} from 'app/components/error/EmailInUseError';
import * as Sentry from '@sentry/browser';
import {AccountDeactivatedError} from 'app/components/error/AccountDeactivatedError';
import {WrongPasswordError} from 'app/components/error/WrongPasswordError';
import {UnknownEmailError} from 'app/components/error/UnknownEmailError';
import {AccountWithoutEmailError} from 'app/components/error/AccountWithoutEmailError';

const codes = {
  'auth/email-already-in-use': email => {
    return {
      code: 'E-Mail existiert bereits',
      message: <EmailInUseError email={email} />,
    };
  },
  'auth/weak-password': () => {
    return {
      code: 'Schwaches Passwort',
      message: 'Wähle ein sicheres Passwort mit mindestens 6 Zeichen.',
    };
  },
  'auth/invalid-email': () => {
    return {
      code: 'Ungültige E-Mail Adresse',
      message: 'Gib bitte eine gültige E-Mail Adresse an.',
    };
  },
  'auth/user-disabled': () => {
    return {
      code: 'Konto deaktiviert',
      message: <AccountDeactivatedError />,
    };
  },
  'auth/user-not-found': () => {
    return {
      code: 'Unbekannte E-Mail Adresse',
      message: <UnknownEmailError />,
    };
  },
  'auth/wrong-password': email => {
    const {code, message} = WrongPasswordError(email);
    return {
      code,
      message,
    };
  },
  'auth/network-request-failed': () => {
    return {
      code: 'Netzwerkfehler',
      message: 'Die Anfrage ist fehlgeschlagen. Versuche es bitte erneut.',
    };
  },
  'auth/web-storage-unsupported': () => {
    return {
      code: 'Drittanbieter Cookies blockiert',
      message: 'In deinem Browser werden notwendige Drittanbieter Cookies blockiert oder dein Browser ist zu alt.',
    };
  },
  'custom/user-not-authenticated': () => {
    return {
      code: 'Authentifizierungsfehler',
      message: 'Die Anfrage ist fehlgeschlagen. Logge dich bitte nochmal ein und versuche es erneut.',
    };
  },
  'custom/missing-email': () => {
    return {
      code: 'E-Mail-Adresse fehlt',
      message: <AccountWithoutEmailError />,
    };
  },
  'custom/email-unverified': () => {
    return {
      code: 'E-Mail-Adresse nicht verifiziert',
      message: 'Bitte klicke auf den Verifizierungslink in der E-Mail, die wir dir gesendet haben, um fortzufahren.',
    };
  },
};

const defaultCode = {
  code: 'Fehler',
  message: 'Es ist ein unerwarteter Fehler aufgetreten.',
};

const ErrorCodeToMessage = (error, email) => {
  if (error && error.code in codes) {
    return codes[error.code](email);
  } else {
    let capturedError = error;
    if (error?.response?.data?.error) {
      Sentry.setExtra('error', capturedError.response.data.error);
      capturedError.name = capturedError.response.data.error?.message || 'Generic Error';
    }
    Sentry.captureException(capturedError);
    return defaultCode;
  }
};

export {ErrorCodeToMessage, codes};
