import React, {useEffect} from 'react';
import * as Sentry from '@sentry/browser';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import makeStore from 'app/services/store';
import createHistory from 'app/services/history';
import ReactDOM from 'react-dom';
import {loadableReady} from '@loadable/component';
import {AppShell} from 'app/components/AppShell';
import {ErrorBoundary} from 'app/components/ErrorBoundary';
import {HelmetProvider} from 'react-helmet-async';
import {AppContainer} from 'app/styles/content';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import materialTheme from 'app/styles/materialTheme';
import {NativeProvider} from 'app/contexts/NativeContext';
import {isNativeFlutterApp} from 'app/components/helpers/helpers';

const history = createHistory();
let root = '/';
if (typeof window !== 'undefined') {
  root = window.location.pathname;
}

const store = makeStore(history, root);
const App = () => {
  useEffect(() => {
    // Remove server-side generated CSS for Material UI
    // According to: https://material-ui.com/guides/server-rendering/
    const materialUIJssStyles = document.querySelector('#jss-server-side');
    if (materialUIJssStyles) {
      materialUIJssStyles.parentElement.removeChild(materialUIJssStyles);
    }
  }, []);

  useEffect(() => {
    if (isNativeFlutterApp()) {
      window.handleNativeResume = () => {};
    }
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <NativeProvider>
          <AppContainer id='app'>
            <ConnectedRouter history={history}>
              <HelmetProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={materialTheme}>
                    <AppShell />
                  </ThemeProvider>
                </StyledEngineProvider>
              </HelmetProvider>
            </ConnectedRouter>
          </AppContainer>
        </NativeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

loadableReady(() => {
  if (!ENVIRONMENT.startsWith('local')) {
    Sentry.init({
      dsn: 'https://e037e7bb04d140f1bfc4cafa7afeda79@sentry.io/1877076',
      environment: ENVIRONMENT,
    });
  }
  ReactDOM.hydrate(<App />, document.getElementById('root'));
});
