const initialData = 'button';

const ButtonTypeReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'SET_SUBMIT_BUTTON_TYPE':
      return action.data;
    default:
      return state;
  }
};

export {ButtonTypeReducer};
