import React, {useState} from 'react';
import {fetchEmailSignInMethods} from 'app/services/firebase';

const WrongPasswordError = (email) => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  fetchEmailSignInMethods(email)
    .then((methods) => {
      const recommendedMethod = methods[0];
      if (methods.length > 0 && recommendedMethod !== 'password' && !methods.includes('password')) {
        setCode('E-Mail existiert bereits');
        setMessage(
          `Dein Konto ist mit ${provider[recommendedMethod]} verbunden. Verwende den ${provider[recommendedMethod]}-Button zum Anmelden.`
        );
      } else {
        setCode('Falsches Passwort');
        setMessage('E-Mail und Passwort stimmen nicht überein.');
      }
    })
    .catch(() => {
      setCode('Falsches Passwort');
      setMessage('Das Passwort ist falsch oder dein Konto nutzt kein Passwort.');
    });

  const provider = {
    'google.com': 'Google',
    'facebook.com': 'Facebook',
    'apple.com': 'Apple',
  };

  return {
    code,
    message,
  };
};

export {WrongPasswordError};
