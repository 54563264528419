import React from 'react';
import {ExternalLink} from 'app/styles/InfoText';

const AccountDeactivatedError = () => {
  return (
    <>
      Der Zugang wurde gesperrt. Bei Rückfragen{' '}
      <ExternalLink decoration='underline' target='_blank' href='https://support.fupa.net/support/tickets/new'>
        FuPa-Team kontaktieren
      </ExternalLink>
      .
    </>
  );
};

export {AccountDeactivatedError};
