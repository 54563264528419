import axios from 'axios';
import * as Sentry from '@sentry/browser';

const fupaApi = axios.create({baseURL: process.env.FUPA_API_URL});
const followApi = axios.create({baseURL: process.env.FOLLOW_URL});

if (process.env.FUPA_API_URL.includes('localhost')) {
  fupaApi.defaults.headers.common['XDEBUG_SESSION'] = 'PHPSTORM';
}

const setAuthorizationHeader = jwt => {
  fupaApi.defaults.headers.common['Authorization'] = jwt ? `Bearer ${jwt}` : '';
  followApi.defaults.headers.common['Authorization'] = jwt ? `Bearer ${jwt}` : '';
};

setAuthorizationHeader();

const postLogin = (jwt, body) => {
  setAuthorizationHeader(jwt);
  // additional error info info for the 422: precondition failed error
  Sentry.addBreadcrumb({
    category: 'xhr',
    message: 'body: ' + JSON.stringify(body),
    level: 'info',
  });
  return fupaApi.post('/logins', body);
};

const updateUser = (firstName, lastName, district, jwt) => {
  if (jwt) {
    setAuthorizationHeader(jwt);
  }
  const params = {
    ...(firstName && {firstName}),
    ...(lastName && {lastName}),
    ...(district && {district}),
  };
  return fupaApi.post('/users', params);
};

// SEARCH ENDPOINTS
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let searchRequest;

const getSearch = query => {
  const request = `${process.env.SEARCH_URL}/query?q=${encodeURIComponent(query)}&type=teamseason&active=true`;
  if (searchRequest) {
    searchRequest.cancel('Only one request allowed at a time.');
  }
  searchRequest = axios.CancelToken.source();
  return fupaApi.get(request, {cancelToken: searchRequest.token}).catch(function(thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled:', thrown.message);
    }
  });
};

const cancelPendingRequest = () => {
  source.cancel('Page was left.');
};

const followTeam = entityId => followApi.post(`/team/${entityId}`);

export {setAuthorizationHeader, postLogin, updateUser, getSearch, cancelPendingRequest, followTeam};
