import {createSelector} from 'reselect';

const selectRedirectUrl = createSelector(
  state => state.redirectUrl,
  redirectUrl => redirectUrl
);

const selectDialogMode = createSelector(
  state => state.dialogMode,
  dialogMode => dialogMode
);

const selectReAuthenticationNeed = createSelector(
  state => state.reAuthenticationNeed,
  reAuthenticationNeed => reAuthenticationNeed
);

const selectCurrentUser = createSelector(
  state => state.currentUser,
  currentUser => currentUser
);

const selectAuthState = createSelector(
  state => state.currentUser,
  currentUser => {
    return !!(currentUser.user === null) || !!Object.keys(currentUser.user).length;
  }
);

const selectJWTCookieName = createSelector(
  state => state.jwtCookieName,
  jwtCookieName => jwtCookieName
);

const selectButtonType = createSelector(
  state => state.buttonType,
  buttonType => buttonType
);

export {
  selectRedirectUrl,
  selectDialogMode,
  selectReAuthenticationNeed,
  selectCurrentUser,
  selectAuthState,
  selectJWTCookieName,
  selectButtonType,
};
