import DuplicatePromiseException from 'app/exceptions/DuplicatePromiseException';

export const debounce = (func, wait, immediate) => {
  let timeout;
  function debounced() {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  }
  function cancel() {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
  }
  debounced.cancel = cancel;
  return debounced;
};

let debouncePromiseTimeout;
export const debouncePromise = (func, wait) => {
  return function (...args) {
    const context = this;

    if (debouncePromiseTimeout) {
      return Promise.reject(new DuplicatePromiseException());
    }

    return new Promise((resolve) => {
      const later = function () {
        debouncePromiseTimeout = null;
        resolve(func.apply(context, args)); // Resolve with the result of the function
      };

      debouncePromiseTimeout = setTimeout(later, wait);
    });
  };
};
