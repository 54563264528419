import { createTheme, adaptV4Theme } from '@mui/material/styles';
import {COLORS, FONTS} from '@fupa/fupa-uikit';

const materialTheme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: FONTS.PTSans,
    button: {
      fontFamily: FONTS.Oswald,
    },
  },
  palette: {
    primary: {
      main: COLORS.fupaPrimary,
    },
    secondary: {
      main: COLORS.fupaSecondary,
    },
    action: {
      main: COLORS.darkGrey,
    },
    error: {
      main: COLORS.red,
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):after': {
          borderBottomColor: COLORS.fupaSecondary,
        },
        '&:hover:not($disabled):before': {
          borderBottomColor: COLORS.fupaSecondary,
        },
      },
    },
  },
}));

export default materialTheme;
