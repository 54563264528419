import {createBrowserHistory, createMemoryHistory} from 'history';

const createHistory = (memoryConfig = {}) => {
  let history = {};
  if (typeof window !== 'undefined') {
    history = createBrowserHistory();
    window.history.replaceState({init: true}, 'initState');
  } else {
    history = createMemoryHistory(memoryConfig);
  }
  return history;
};

export default createHistory;
