import React, {useCallback, useEffect, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import routes from 'app/components/routesConfig';
import styled from 'styled-components';
import {Content} from 'app/styles/content';
import {COLORS, ELEVATIONS, SIZES} from '@fupa/fupa-uikit';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {setRedirectURL} from 'app/actions/RedirectUrlActions';
import {setJwtCookieName} from 'app/actions/JwtCookieNameActions';
import {Helmet} from 'react-helmet-async';
import {selectAuthState, selectCurrentUser, selectDialogMode} from 'app/hooks/reduxCreateSelectorHooks';
import {checkExpirationOfToken, initFirebase} from 'app/services/firebase';
import {generateUniqueTemplateKey} from 'app/services/gtm';
import {generateTrackingService} from 'app/services/trackingTrigger';
import {useLocation} from 'react-router';
import {updateButtonType} from 'app/actions/ButtonTypeActions';
import {sendEventToGA4} from 'app/components/helpers/gtagHelpers';
import {useConsent} from 'app/components/helpers/consent';
import {useConnectionStatus} from 'app/components/helpers/connectionStatus';
import {updateDialogMode} from 'app/actions/DialogModeActions';
import {useNative} from 'app/contexts/NativeContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.white};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: ${ELEVATIONS.Card};

  @media screen and (min-width: 500px) {
    max-width: 25rem;
    height: 39rem;
  }
`;

const Header = styled.a`
  box-sizing: content-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  background-color: ${COLORS.fupaPrimary};

  @media screen and (min-width: 500px) {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
`;

const Brand = styled.img`
  justify-self: center;
  flex: 1;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.layout ? props.layout : 'row')};
  padding: ${props => (props.noPadding ? '0' : SIZES['12'])};
  width: 100%;
  height: 100%;
  background-color: ${COLORS.white};
  overflow: hidden;
`;

const selectQueryParams = createSelector(
  state => state.router.location,
  state => state.currentUser.districtSlug,
  (location, districtSlug) => {
    const redirectFallback = districtSlug ? `/region/${districtSlug}` : '/select';
    const redirectUrl =
      location.query && location.query.redirectUrl ? decodeURIComponent(location.query.redirectUrl) : redirectFallback;
    const jwtCookieName = location.query && location.query.cookie ? decodeURIComponent(location.query.cookie) : '';
    const blank = !!(location.query && location.query.blank);
    return {redirectUrl, jwtCookieName, blank};
  }
);

const AppShell = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {redirectUrl, jwtCookieName, blank} = useSelector(selectQueryParams);
  const isAuthenticated = useSelector(selectAuthState);
  const {initializing, user, claims, districtSlug} = useSelector(selectCurrentUser);
  const initCMPScript = <script type='text/javascript' src='/cmp/cmp_min.js' async='async' />;
  const [currentPageTitle, setCurrentPageTitle] = useState('');
  const dialogMode = useSelector(selectDialogMode);
  const nativeInfo = useNative();

  useEffect(() => initFirebase(dispatch, jwtCookieName, redirectUrl, districtSlug, nativeInfo), []);
  const pushGA4 = generateTrackingService();

  useEffect(() => {
    pageViewEvent(location.pathname, location.search, location.hash, currentPageTitle);
  }, [location, currentPageTitle]);

  const pageViewEvent = useCallback((pathname, search, hash, title) => {
    const page = pathname + search + hash;
    const template = generateUniqueTemplateKey(pathname);

    pushGA4({page_title: title, page_location: document.location, content_group: template}, page, event =>
      sendEventToGA4('page_view', event)
    );
  }, []);

  useConsent();
  useConnectionStatus();

  useEffect(() => {
    dispatch(updateDialogMode(Boolean(blank)));
    dispatch(setRedirectURL(redirectUrl));
    dispatch(setJwtCookieName(jwtCookieName));
    dispatch(updateButtonType(window.FUPA_BUTTON_TYPE));
  }, []);

  useEffect(() => {
    window.dialogMode = dialogMode;
  }, [dialogMode]);

  // Register listeners to evaluate expiry of user token
  useEffect(() => {
    let intervalId;
    if (Object.keys(user).length === 0) {
      return;
    }

    function handleExpirationCheck() {
      checkExpirationOfToken(user, claims.exp).catch(error => {
        if (error.code === 'auth/user-disabled') {
          history.push('/');
        }
        clearInterval(intervalId);
      });
    }

    // Check token expiration date every 60s
    intervalId = setInterval(handleExpirationCheck, 60000);
    // Executes handler on focus change, e.g. window paused / in background and comes to foreground again
    window.addEventListener('focus', handleExpirationCheck);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('focus', handleExpirationCheck);
    };
  });

  if (blank || dialogMode) {
    return (
      <Card noPadding layout='column'>
        {renderRoutes(routes, {isAuthenticated, initializing, isCompleted: claims?.u})}
      </Card>
    );
  } else {
    return (
      <Content>
        <Helmet
          defaultTitle='FuPa'
          titleTemplate='%s - FuPa'
          onChangeClientState={newState => {
            setCurrentPageTitle(newState.title);
          }}>
          {initCMPScript}
        </Helmet>
        <Wrapper>
          <Header href={'/'}>
            <Brand src='FuPa_Logo.svg' height={45} alt='FuPa Logo' />
          </Header>
          <Card layout='column'>{renderRoutes(routes, {isAuthenticated, initializing, isCompleted: claims?.u})}</Card>
        </Wrapper>
      </Content>
    );
  }
};

export {AppShell};
