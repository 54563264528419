import {useEffect, useState} from 'react';
import {setDimensionToGA4} from 'app/components/helpers/gtagHelpers';

const useConnectionStatus = () => {
  const [connectionStatus, setConnectionStatus] = useState(null);

  function updateConnectionStatus() {
    setConnectionStatus(navigator.connection.effectiveType);
  }

  useEffect(() => {
    if (navigator?.connection?.effectiveType) {
      setConnectionStatus(navigator.connection.effectiveType);
      navigator.connection.addEventListener('change', updateConnectionStatus);
    } else {
      setConnectionStatus('not supported');
    }
  }, []);

  useEffect(() => {
    if (connectionStatus) {
      setDimensionToGA4({user_properties: {fupa_connection_status: connectionStatus}});
    }
  }, [connectionStatus]);

  return connectionStatus;
};

export {useConnectionStatus};
