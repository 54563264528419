import styled from 'styled-components';
import {Button, COLORS, Typography} from '@fupa/fupa-uikit';

const AppContainer = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  @media screen and (min-width: 500px) {
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin: 1rem 0 1.5rem 0;
  &&& {
    &:disabled {
      background-color: ${COLORS.lightestGrey};
      cursor: default;
    }
    width: 13.5rem;
  }
`;

const Emph = styled(Typography)`
  &&& {
    color: ${COLORS.darkestGrey};
  }
  white-space: nowrap;
`;

const Secondary = styled.span`
  color: ${COLORS.fupaSecondary};
  cursor: pointer;
`;

export {Content, StyledButton, Emph, Secondary, AppContainer};
