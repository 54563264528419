const initialData = '';

const ReAuthenticationReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'RE_AUTHENTICATION_NEED_UPDATE':
      return action.data;
    default:
      return state;
  }
};

export {ReAuthenticationReducer};
